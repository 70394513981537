import React, { useMemo } from 'react'

import { Text, View } from '@react-pdf/renderer'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import styles from '../../../forms/Template/Printable/styles'
import { PrintableProps } from '../../../types/forms'
import Currency from '../Currency'

type Props = {
    dispute: {
        amount: number
        date: string
        description: string
        hour: string
        id: number
    }[]
}

const TablePdf = ({ dispute, values }: Props & PrintableProps) => {
    const { t } = useTranslation()

    const totalAmount = useMemo(
        () =>
            Object.keys(values)
                .filter((key) => key.startsWith('dispute-item') && values[key] !== undefined)
                .reduce((total, key) => total + 100 * values[key].amount, 0) / 100,

        [values]
    )

    const getConcatStyle = (index: number) => (index % 2 === 0 ? { ...styles.Tr, ...styles.Odd } : styles.Tr)

    return (
        <View>
            <View style={styles.Table}>
                <View style={styles.Head}>
                    <Text style={styles.Th}>{t('fields:dispute-item.date.label')}</Text>
                    <Text style={styles.Th}>{t('fields:dispute-item.description.label')}</Text>
                    <Text style={styles.Th}>{t('fields:dispute-item.amount.label')}</Text>
                </View>
                <View style={styles.Body}>
                    {dispute.map((cell, i) => (
                        <View style={getConcatStyle(i)} key={`dispute-table-${cell.id}`}>
                            <View style={styles.Td}>
                                <Text style={styles.Label}>
                                    {cell.date && format(parseISO(cell.date), 'dd/MM/yyyy')}
                                </Text>
                            </View>
                            <View style={styles.Td}>
                                <Text style={styles.Value}>{cell.description}</Text>
                            </View>
                            <View style={styles.Td}>
                                <Currency amount={cell.amount} />
                            </View>
                        </View>
                    ))}
                </View>
            </View>
            <View style={styles.Total}>
                <Text>{t('fields:dispute-item.total')}</Text>
                <Currency amount={totalAmount} />
            </View>
        </View>
    )
}

export default TablePdf
