import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import useFormField from '@nickel/forms/hooks/useFormField'
import { requiredString } from '@nickel/kyc/fields/validators'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'

const name = 'lastName'

const Name = () => {
    const { t } = useTranslation()
    const { error, inputProps } = useFormField<string>(name, [requiredString])
    return (
        <Field error={error} htmlFor={name}>
            <Input {...inputProps} name={name} label={t('forms:fatca.last-name.label')} />
        </Field>
    )
}

export default memo(Name)
