import React, { useMemo } from 'react'

import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import { DecimalSeparator } from '@nickel/ui/components/legacy/Currency/constants'
import { convertNumberToFormattedString } from '@nickel/ui/components/legacy/Currency/utils'

import styles from '../../../forms/Template/Printable/styles'

type Props = {
    amount?: number
    cents?: boolean
    currency?: string
    signed?: boolean
    withCents?: boolean
}

const CurrencyPdf = ({ amount = 0, cents = true, currency = '€', signed = false, withCents = true }: Props) => {
    const { i18n } = useTranslation()

    const currentLocale = i18n.language

    const sign = amount < 0 ? '- ' : '+ '

    const formattedAmount: string = useMemo(
        () => convertNumberToFormattedString(currentLocale, signed ? Math.abs(amount) : amount, cents),
        [currentLocale, amount, cents, signed]
    )

    const separator = DecimalSeparator[currentLocale as keyof typeof DecimalSeparator]
    const [_ints, _cents] = formattedAmount.split(separator)

    return (
        <View style={styles.Row}>
            {signed && <Text>{sign}</Text>}
            <Text>{_ints}</Text>
            {withCents && <Text>{`${separator}${_cents}`}</Text>}
            <Text>{` ${currency}`}</Text>
        </View>
    )
}

export default CurrencyPdf
