import React, { ComponentProps, FC, useEffect, useState } from 'react'

import { I18nextProvider } from 'react-i18next'

import i18n from './index'

export function I18nProvider({ children }: ComponentProps<FC>) {
    return <WaitForI18NextInitialized i18nInstance={i18n}>{children}</WaitForI18NextInitialized>
}

interface WaitForI18NextInitializedProps extends ComponentProps<FC> {
    i18nInstance: typeof i18n
}

export function WaitForI18NextInitialized({ i18nInstance, children }: WaitForI18NextInitializedProps) {
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (i18nInstance.isInitialized) {
            setInitialized(true)
        } else {
            i18nInstance.on('initialized', () => setInitialized(true))
        }
    }, [i18nInstance])

    if (initialized) {
        return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
    }
    return <></>
}
