import React, { memo } from 'react'

import useFormField from '@nickel/forms/hooks/useFormField'

import { AdditionalTaxCountries } from './AdditionalTaxCountries'
import { multipleTaxCountriesRadioFieldname } from './const'
import { MultipleTaxCountriesRadio } from './MultipleTaxCountriesRadio'

export const TaxResidence = memo(() => {
    const { inputProps } = useFormField<string>(multipleTaxCountriesRadioFieldname)

    const hasMultipleTaxResidences = inputProps.value === 'other-countries-fiscal-resident'

    return (
        <>
            <MultipleTaxCountriesRadio />
            {hasMultipleTaxResidences && <AdditionalTaxCountries />}
        </>
    )
})

TaxResidence.displayName = 'TaxResidence'
