import React, { useMemo } from 'react'

import { useFormContext } from 'react-form'

import Currency from '@nickel/ui/components/legacy/Currency'

const DisputeTotal = () => {
    const { values } = useFormContext()
    const amount = useMemo(
        () =>
            Object.keys(values)
                .filter((key) => key.startsWith('dispute-item') && values[key] !== undefined)
                .reduce((total, key) => total + 100 * values[key].amount, 0) / 100,

        [values]
    )

    return <Currency pretty bold amount={amount} />
}

export default DisputeTotal
