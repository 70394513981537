import React from 'react'

import { useTranslation, Trans } from 'react-i18next'

import Text from '@nickel/ui/components/legacy/Text'

import { getTranslatedScreenShot } from '../../../../components/Image/GetTranslatedScreenShot'
import Link from '../../../../components/Link/Link'
import SelfieCheckbox from '../../../../components/SelfieCheckbox'
import { FormType } from '../../../../types/forms'
import Styled from '../style'

type Props = {
    formType: FormType
}
function Instructions({ formType }: Props) {
    const { t, i18n } = useTranslation()
    return (
        <>
            <Styled.InstructionSpan>
                <Trans i18nKey="forms:edit.instructions_5" context={formType} components={{ bold: <b /> }} />
            </Styled.InstructionSpan>
            <SelfieCheckbox visible name="attach-id-document" />
            <Styled.InstructionSpan>
                <Trans
                    i18nKey="forms:edit.instructions_6"
                    context={formType}
                    components={{
                        anchor: <Link href={t('forms:url.contact-form')} />,
                        bold: <b />
                    }}
                />
            </Styled.InstructionSpan>
            <img src={getTranslatedScreenShot(i18n.language)} alt="phone number change form" width="100%" />
            <Styled.InstructionSpan>
                <Trans
                    i18nKey="forms:edit.instructions_6_2"
                    context={formType}
                    components={{ list: <ul style={{ margin: 0 }} />, entry: <li /> }}
                />
            </Styled.InstructionSpan>
            <Styled.InstructionSpan>
                <Text>{t('forms:edit.instructions_7', { context: formType })}</Text>
            </Styled.InstructionSpan>
        </>
    )
}

export default Instructions
