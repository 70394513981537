import { StyleSheet, Font } from '@react-pdf/renderer'

import { Color, FontWeight } from '@nickel/ui/tokens'

Font.register({
    family: 'MullerNarrow',
    fonts: [
        { src: 'https://static-resources.nickel.eu/fonts/MullerNarrow.ttf' },
        { src: 'https://static-resources.nickel.eu/fonts/MullerNarrow-Medium.ttf', fontWeight: 500 }
    ]
})

export default StyleSheet.create({
    Fieldset: {
        paddingLeft: 20
    },
    Legend: {
        fontFamily: 'MullerNarrow',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 9,
        textTransform: 'uppercase'
    },
    Label: {
        fontFamily: 'MullerNarrow',
        fontSize: 16,
        fontWeight: 500
    },
    Value: {
        fontFamily: 'MullerNarrow',
        fontSize: 16
    },
    Page: {
        padding: 30
    },
    Title: {
        fontFamily: 'MullerNarrow',
        fontSize: 20
    },
    Separator: {
        backgroundColor: Color.OUTLINE,
        height: 1,
        margin: '18px 0',
        width: '100%'
    },
    Row: {
        display: 'flex',
        flexDirection: 'row'
    },
    Col: {
        flex: 1
    },
    Total: {
        backgroundColor: Color.BACKGROUND_1,
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'MullerNarrow',
        fontSize: 18,
        fontWeight: FontWeight.MEDIUM,
        justifyContent: 'flex-end',
        marginBottom: 18,
        marginTop: 18,
        padding: 18,
        width: '100%'
    },
    Table: {
        width: '100%'
    },
    Head: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    Body: {
        width: '100%'
    },
    Th: {
        flex: 1,
        fontFamily: 'MullerNarrow',
        fontSize: 18,
        fontWeight: 500,
        textAlign: 'center',
        padding: 9
    },
    Tr: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    Td: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        fontFamily: 'MullerNarrow',
        fontSize: 16,
        justifyContent: 'center',
        padding: 9
    },
    Odd: {
        backgroundColor: Color.BACKGROUND_1
    }
})
