import React from 'react'

import { Text, View } from '@react-pdf/renderer'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import FirstName from '@nickel/kyc/fields/basicInfo/FirstName'
import LastName from '@nickel/kyc/fields/basicInfo/LastName'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

import styles from '../forms/Template/Printable/styles'
import { PrintableProps } from '../types/forms'

export const CivilityEditable = () => {
    const { t } = useTranslation()
    return (
        <Row>
            <Col>
                <Fieldset legend={t('fields:civility')}>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FirstName />
                        </Col>
                        <Col xs={12} sm={6}>
                            <LastName />
                        </Col>
                    </Row>
                </Fieldset>
            </Col>
        </Row>
    )
}

export const CivilityPrintable = ({ values }: PrintableProps) => {
    const { t } = useTranslation()
    return (
        <>
            <Text style={styles.Legend}>{t('common:information')}</Text>
            <View style={styles.Row}>
                <Text style={styles.Label}>{`${t('basic-info:last-name')} : `}</Text>
                <Text style={styles.Value}>{values.lastName}</Text>
            </View>
            <View style={styles.Row}>
                <Text style={styles.Label}>{`${t('basic-info:first-name')} : `}</Text>
                <Text style={styles.Value}>{values.firstName}</Text>
            </View>
        </>
    )
}
