import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormField } from '@nickel/forms/hooks'
import Switch from '@nickel/ui/components/legacy/form/Checkbox/Switch'
import Field from '@nickel/ui/components/legacy/form/Field'

const name = 'registration-type'
const RegistrationType = () => {
    const { t } = useTranslation()
    const { inputProps } = useFormField<string>(name)
    const toggle = useCallback(() => {
        inputProps.onChange(inputProps.value === 'NOB' ? 'CNJ' : 'NOB')
    }, [inputProps])
    return (
        <Field htmlFor={name} subLabel={t('fields:uncheck-minor')}>
            <Switch checked={inputProps.value === 'NOB'} onClick={toggle} />
        </Field>
    )
}

export default RegistrationType
