import React, { memo, useCallback, useEffect } from 'react'

import { format } from 'date-fns'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useFormField } from '@nickel/forms/hooks'
import useFormSection from '@nickel/forms/hooks/useFormSection'
import { requiredDate, requiredString } from '@nickel/kyc/fields/validators'
import XCircle from '@nickel/ui/components/icons/XCircle'
import CurrencyInput from '@nickel/ui/components/legacy/form/CurrencyInput'
import DateInput from '@nickel/ui/components/legacy/form/DateInput'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'
import { Color, FontSize } from '@nickel/ui/tokens'

type Props = {
    id?: string
    index: number
    removeDispute: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Container = styled.div`
    border-bottom: 1px solid ${Color.OUTLINE};
    margin-bottom: 1rem;

    &:nth-child(n + 2) > div:nth-child(4) {
        max-width: calc(100% - 3rem);
    }

    > div:nth-child(5) {
        position: absolute;
        right: 1.5rem;
        margin-top: -5.5rem;
    }

    @media (min-width: 500px) {
        display: flex;
        justify-content: space-between;
        border: 0;

        > div {
            margin-right: 1rem;

            :first-child {
                width: 6rem;
            }

            :nth-child(2) {
                flex-grow: 1;
            }
            :nth-child(3) {
                width: 7rem;
                margin-right: 0;
            }

            :nth-child(4) {
                position: static;
                margin-top: 0;
                width: 0;
            }
        }
    }

    ${XCircle} {
        fill: ${Color.ERROR};
        font-size: ${FontSize.XLARGE};
        margin-top: 2.4rem;
        margin-left: 0.5rem;
    }
`

const dateRules = [requiredDate]
const descriptionRules = [requiredString]
const amountRules = (t: TFunction) => [
    {
        validator: (value: number | undefined) => value !== undefined && value > 0,
        message: () => t('fields:dispute-item.errors.negative')
    }
]

const DisputeFields = ({ index, removeDispute }: Props) => {
    const { t } = useTranslation()

    const { inputProps: dateInputProps, setValue: setDateValue, error: dateError } = useFormField<string>(
        'date',
        dateRules
    )
    const {
        inputProps: descriptionInputProps,
        setValue: setDescriptionValue,
        error: descriptionError
    } = useFormField<string>('description', descriptionRules)

    const { inputProps: amountInputProps, setValue: setAmountValue, error: amountError } = useFormField<
        number | undefined
    >('amount', amountRules(t))

    useEffect(() => {
        setDateValue(format(new Date(), 'yyyy-MM-dd'))
        setDescriptionValue('')
        setAmountValue(0)
    }, [setAmountValue, setDateValue, setDescriptionValue])

    return (
        <Container aria-label={t('fields:dispute-item.label', { itemNumber: index })}>
            <Field error={dateError} htmlFor={`disputed-item-date-${index}`}>
                <DateInput
                    {...dateInputProps}
                    onChange={dateInputProps.onChange as (val?: string) => void}
                    label={t('fields:dispute-item.date.label')}
                    name={`disputed-item-date-${index}`}
                />
            </Field>
            <Field error={descriptionError} htmlFor={`disputed-item-description-${index}`}>
                <Input
                    {...descriptionInputProps}
                    label={t('fields:dispute-item.description.label')}
                    name={`disputed-item-description-${index}`}
                />
            </Field>
            <Field error={amountError} htmlFor={`disputed-item-amount-${index}`}>
                <CurrencyInput
                    {...amountInputProps}
                    label={t('fields:dispute-item.amount.label')}
                    name={`disputed-item-amount-${index}`}
                />
            </Field>
            {/* eslint-disable-next-line */}
            <div onClick={removeDispute} role="button" aria-label={t('fields:dispute-item.remove', { itemNumber: index })}>
                {index !== 0 && <XCircle css="" />}
            </div>
        </Container>
    )
}

interface DisputeItemProps {
    id: string
    index: number
    removeDispute: (id: string) => void
}

const DisputeItem = ({ id, index, removeDispute }: DisputeItemProps) => {
    const { Section, setValue } = useFormSection<string | undefined>(`dispute-item-${id}`)

    const _removeDispute = useCallback(() => {
        removeDispute(id)
        setValue(() => undefined)
    }, [id, removeDispute, setValue])
    return (
        <Section>
            <DisputeFields id={id} index={index} removeDispute={_removeDispute} />
        </Section>
    )
}

export default memo(DisputeItem)
