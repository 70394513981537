import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

const Privacy = () => {
    const { t } = useTranslation()
    return (
        <Fieldset legend={t('forms:fatca.privacy.title')}>
            <p>{t('forms:fatca.privacy.content')}</p>
        </Fieldset>
    )
}

export default memo(Privacy)
