import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Text from '@nickel/ui/components/legacy/Text'
import { Color, FontSize } from '@nickel/ui/tokens'

import Logo from './Logo'

const Container = styled.header`
    text-align: center;
    border-bottom: 1px solid ${Color.OUTLINE};
    padding-bottom: 1rem;
`

const Header = styled.div`
    padding-bottom: 2rem;
`

function HeaderFatca() {
    const { t } = useTranslation()
    return (
        <Header>
            <Container>
                <Logo />
                <Text fontSize={FontSize.XLARGE}>
                    <Trans i18nKey="forms:edit.title_FATCA" components={{ bold: <b /> }} />
                </Text>
            </Container>
            <p>
                <Text>{t('forms:edit.instructions_1_FATCA')}</Text>
                <br />
                <br />
                <Text>{t('forms:edit.instructions_2_FATCA')}</Text>
                <a href={t('forms:url.contact-form')} target="_blank" rel="noreferrer">
                    {t('forms:url.contact-form')}
                </a>
            </p>
        </Header>
    )
}

export default HeaderFatca
