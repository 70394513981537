import React, { useState } from 'react'

import styled from 'styled-components'

import Field from '@nickel/ui/components/legacy/form/Field'
import Radios from '@nickel/ui/components/legacy/form/Radios'
import Heading from '@nickel/ui/components/legacy/Heading'
import Link from '@nickel/ui/components/legacy/Link'
import { Spacing } from '@nickel/ui/tokens'

import { customerFormsI18nDictionaries } from '../i18n'

import { formNames } from './Router'

const Container = styled.section`
    padding: ${Spacing.XXWIDE};
`

const LangContainer = styled.section`
    & > div {
        flex-direction: row;
        gap: ${Spacing.XLARGE};
    }
`

const LinkContainer = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: ${Spacing.WIDE};
`

const supportedLanguages = Object.keys(customerFormsI18nDictionaries).filter((locale) => locale !== 'root')

const LANGS = [
    {
        label: 'default',
        value: ''
    },
    ...supportedLanguages.map((locale) => ({ label: locale, value: locale }))
]

export function Home() {
    const [lang, setLang] = useState<string | null>(null)
    return (
        <Container>
            <Heading.H1>customer-forms</Heading.H1>
            <Field>
                <LangContainer>
                    <Radios
                        options={LANGS}
                        onChange={(value) => setLang(value === '' ? null : value)}
                        value={lang ?? ''}
                    />
                </LangContainer>
            </Field>
            <LinkContainer>
                {formNames.map((linkFragment) => {
                    return (
                        <Link
                            key={linkFragment}
                            href={`${window.REACT_APP_PATHNAME_PREFIX}/${linkFragment}${
                                lang === null ? '' : `?lng=${lang}`
                            }`}
                        >
                            {linkFragment}
                        </Link>
                    )
                })}
            </LinkContainer>
        </Container>
    )
}
