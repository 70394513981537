import React, { useMemo } from 'react'

import { Text, View } from '@react-pdf/renderer'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { PrintableProps } from '../../types/forms'
import styles from '../Template/Printable/styles'

import { multipleTaxCountriesRadioFieldname } from './components/TaxResidence/const'
import { useAddressFormatter } from './hooks/useAddressFormatter'

function getOtherNationalities(otherNationalities: string[], t: (key: string) => string) {
    if (otherNationalities && otherNationalities.length > 0) {
        const nationalities = otherNationalities.map((nationality) => t(`countries:${nationality}`)).join(', ')
        return `, ${nationalities}`
    }
    return ''
}

const Printable = ({ values }: PrintableProps) => {
    const { t } = useTranslation()

    const formattedFiscalAddress = useAddressFormatter({ values })

    const additionnalTaxResidences = useMemo(() => {
        if (
            !values[multipleTaxCountriesRadioFieldname] ||
            !values['additional-tax-countries'] ||
            !values['additional-tax-countries'][0].country
        )
            return []

        return values['additional-tax-countries'].map((value: { country: string; taxNumber: string }) => {
            const { country, taxNumber } = value
            return (
                <View style={styles.Row} key={country}>
                    <Text style={styles.Label}>{`- ${t(`countries:${country}`)}: `}</Text>
                    <Text style={styles.Value}>{taxNumber}</Text>
                </View>
            )
        })
    }, [values, t])

    return (
        <View>
            <View>
                <View style={styles.Fieldset}>
                    <Text style={styles.Legend}>{t('forms:fatca.identification')}</Text>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('forms:fatca.last-name.label')} : `}</Text>
                        <Text style={styles.Value}>{values.lastName}</Text>
                    </View>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('basic-info:first-name')} : `}</Text>
                        <Text style={styles.Value}>{values.firstName}</Text>
                    </View>
                    {values['name-used'] ? (
                        <View style={styles.Row}>
                            <Text style={styles.Label}>{`${t('forms:fatca.name-used.label')} : `}</Text>
                            <Text style={styles.Value}>{values['name-used']}</Text>
                        </View>
                    ) : (
                        <View />
                    )}
                    <View>
                        <Text style={styles.Label}>{`${t('forms:fatca.complete-address')} : `}</Text>
                        <Text style={styles.Value}>{values.address?.formattedAddress}</Text>
                    </View>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('forms:fatca.is-adult')} : `}</Text>
                        <Text style={styles.Value}>
                            {values['registration-type'] === 'CNJ' ? t('common:no') : t('common:yes')}
                        </Text>
                    </View>
                </View>
                <View style={{ ...styles.Fieldset, paddingTop: 18 }}>
                    <Text style={styles.Legend}>{t('birth:title')}</Text>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('basic-info:birth-date')} : `}</Text>
                        <Text style={styles.Value}>
                            {values.birth?.date && parseISO(values.birth?.date).toLocaleDateString()}
                        </Text>
                    </View>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('birth:birth-place.label')} : `}</Text>
                        <Text style={styles.Value}>{t(`countries:${values.birth?.place}`)}</Text>
                    </View>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('birth:birth-country.label')} : `}</Text>
                        <Text style={styles.Value}>{t(`countries:${values.birth?.country}`)}</Text>
                    </View>
                    <View style={styles.Row}>
                        <Text style={styles.Label}>{`${t('fields:nationalities')} : `}</Text>
                        <Text style={styles.Value}>{t(`countries:${values.birth?.nationality}`)}</Text>
                        <Text style={styles.Value}>{getOtherNationalities(values.birth?.otherNationalities, t)}</Text>
                    </View>
                </View>
                <View style={{ ...styles.Fieldset, paddingTop: 18 }}>
                    <Text style={styles.Legend}>{t('forms:fatca.fiscal-address')}</Text>
                    <Text style={styles.Label}>
                        {t('forms:fatca.tax-resident.fiscal-address')}
                        {' : '}
                    </Text>
                    <Text style={styles.Value}>{formattedFiscalAddress}</Text>
                    {additionnalTaxResidences.length > 0 ? (
                        <>
                            <Text style={styles.Value}>
                                {t('forms:fatca.tax-resident.declaration-multiple-pdf')}
                                {' :'}
                            </Text>
                            <View>{additionnalTaxResidences}</View>
                        </>
                    ) : null}
                </View>
            </View>
            <View>
                <View style={{ ...styles.Fieldset, paddingTop: 18 }}>
                    <Text style={styles.Legend}>{t('forms:fatca.us-citizenship-declaration')}</Text>
                    <Text style={{ ...styles.Value, fontSize: 14 }}>
                        {t(`forms:fatca.us-citizen.${values['us-citizen']}`)}
                    </Text>
                </View>
                <View style={{ ...styles.Fieldset, paddingTop: 18 }} break>
                    <Text style={styles.Legend}>{t('forms:fatca.privacy.title')}</Text>
                    <Text style={{ ...styles.Value, fontSize: 14 }}>{t('forms:fatca.privacy.content')}</Text>
                </View>
                <View style={{ ...styles.Fieldset, paddingTop: 18 }}>
                    <Text style={styles.Legend}>{t('forms:fatca.certification.title')}</Text>
                    <Text style={{ ...styles.Value, fontSize: 14 }}>{t('forms:fatca.certification.content')}</Text>
                </View>
            </View>
        </View>
    )
}
export default Printable
