import React, { ComponentType, memo } from 'react'

import { useForm } from 'react-form'

import InheritedPropertiesProvider from '@nickel/ui/inheritedProperties/InheritedPropertiesProvider'

import { FormType, PrintableProps } from '../../types/forms'

import Editable from './Editable'

type Props = {
    EditableContent: ComponentType
    PrintableContent: ComponentType<PrintableProps>
    formType: FormType
}

const defaultValues = {
    'registration-type': 'NOB'
}

const Template = ({ EditableContent, formType, PrintableContent }: Props) => {
    const { Form } = useForm({ defaultValues })

    return (
        <InheritedPropertiesProvider>
            <Form>
                <Editable EditableContent={EditableContent} formType={formType} PrintableContent={PrintableContent} />
            </Form>
        </InheritedPropertiesProvider>
    )
}

export default memo(Template)
