import FormDE from '../../assets/images/PHONE-de-DE.png'
import FormENBE from '../../assets/images/PHONE-en-BE.png'
import FormES from '../../assets/images/PHONE-es-ES.png'
import FormFRBE from '../../assets/images/PHONE-fr-BE.png'
import FormFR from '../../assets/images/PHONE-fr-FR.png'
import FormNLBE from '../../assets/images/PHONE-nl-BE.png'
import FormPT from '../../assets/images/PHONE-pt-PT.png'

export default {
    FormDE,
    FormENBE,
    FormES,
    FormFRBE,
    FormFR,
    FormNLBE,
    FormPT
}
