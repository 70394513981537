import React from 'react'

import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import Address from '@nickel/kyc/fields/address'
import BirthDate from '@nickel/kyc/fields/basicInfo/BirthDate'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

import CheckboxField from '../../../components/Checkbox'
import { CivilityEditable } from '../../../components/Civility'
import { Disputes } from '../../../components/Disputes/Disputes'

import AgencyName from './fields/AgencyName'
import BankName from './fields/BankName'

const Editable = () => {
    const { t } = useTranslation()
    return (
        <>
            <CivilityEditable />
            <Row>
                <Col xs={12}>
                    <Fieldset legend={t('fields:nickel-information')}>
                        <BirthDate isChild={false} placeholder={t('fields:date-placeholder')} />
                    </Fieldset>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Fieldset legend={t('forms:withdrawal-description')}>
                        <Address label={t('fields:withdrawal-location')} provider="capadress" placeholder=" " light />
                        <Row>
                            <Col xs={12} sm={6}>
                                <BankName />
                            </Col>
                            <Col xs={12} sm={6}>
                                <AgencyName />
                            </Col>
                        </Row>
                        <CheckboxField name="joined-receipt" />
                    </Fieldset>
                </Col>
            </Row>
            <Disputes />
        </>
    )
}
export default Editable
