import React, { memo, useCallback, useRef, useState } from 'react'

import SignatureCanvas from 'react-signature-canvas'

import { ArrowLoop } from '@nickel/ui/components/icons'

import Styled, { SIGNATURE_CLASSNAME } from '../../../../styles'

interface Props {
    onChange?: (url?: string) => void
}

const Signature = ({ onChange }: Props) => {
    const signature = useRef<SignatureCanvas>(null)
    const [signed, setSigned] = useState(!signature.current?.isEmpty() ?? false)

    const changeSignature = useCallback(() => {
        const url = signature.current?.toDataURL()
        if (url) {
            onChange?.(url)
        }
        setSigned(true)
    }, [onChange])

    const resetSignature = useCallback(() => {
        signature.current?.clear()
        onChange?.()
        setSigned(false)
    }, [onChange])

    return (
        <Styled.SignatureContainer signed={signed}>
            <ArrowLoop css="" onClick={resetSignature} />
            <SignatureCanvas
                canvasProps={{ width: 350, height: 200, className: SIGNATURE_CLASSNAME }}
                onEnd={changeSignature}
                penColor="black"
                ref={signature}
            />
        </Styled.SignatureContainer>
    )
}

export default memo(Signature)
