import React from 'react'

import { useField } from 'react-form'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Input from '@nickel/ui/components/legacy/form/Input'

import { TaxResidenceCountry } from '../TaxResidenceCountry'

const TaxResidenceButton = styled.button.attrs({ type: 'button' })`
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;
    font-size: 2rem;
    margin-bottom: 1rem;
    width: 2.5rem;
`

const FIELD_NAME = 'additional-tax-countries'

interface ResidenceCountry {
    country: string
    taxNumber: string
}

const requiredField = (value: ResidenceCountry[]) => {
    if (value.length > 0) return false
    return 'Required'
}

const DEFAULT_VALUES: ResidenceCountry[] = [{ country: '', taxNumber: '' }]

export function AdditionalTaxCountries() {
    const { t } = useTranslation()

    const { value: values, setValue: setValues } = useField<ResidenceCountry[]>(FIELD_NAME, {
        validate: requiredField,
        defaultValue: DEFAULT_VALUES
    })

    const changeCountry = (newCountry: string, oldCountry: string) => {
        if (values.some((value) => value.country === newCountry)) return
        const newValues = values.map((residenceCountry) => {
            if (residenceCountry.country === oldCountry)
                return { country: newCountry, taxNumber: residenceCountry.taxNumber }
            return { ...residenceCountry }
        })
        setValues(newValues)
    }

    const changeTaxNumber = (country: string, newTaxNumber: string) => {
        const newValues = values.map((residenceCountry) => {
            if (residenceCountry.country !== country) return residenceCountry
            return { ...residenceCountry, taxNumber: newTaxNumber }
        })
        setValues(newValues)
    }

    const addTaxResidenceCountry = () => {
        const hasEmptyCountry = values.some(({ country, taxNumber }) => !country || !taxNumber)
        if (hasEmptyCountry) return

        setValues([...values, { country: '', taxNumber: '' }])
    }

    const removeTaxResidenceCountry = (countryToDelete: string) => {
        setValues(values.filter(({ country }) => country !== countryToDelete))
    }

    return (
        <>
            <Row>
                <Col>
                    <p>
                        <em>{t('forms:fatca.tax-resident.guideline')}</em>
                    </p>
                </Col>
            </Row>

            {values.map(({ country, taxNumber }) => (
                <Row key={country} data-testid="tax-country-row">
                    <Col xs={10}>
                        <Row>
                            <Col>
                                <TaxResidenceCountry
                                    name={`${FIELD_NAME}-${country}`}
                                    value={country}
                                    onChange={(newCountry) => {
                                        changeCountry(newCountry, country)
                                    }}
                                />
                            </Col>
                            <Col>
                                <Input
                                    value={taxNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        changeTaxNumber(country, event.target.value)
                                    }}
                                    name={`${FIELD_NAME}-${taxNumber}`}
                                    placeholder={t('forms:fatca.tax-number')}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        {values.length > 1 && (
                            <TaxResidenceButton onClick={() => removeTaxResidenceCountry(country)}>
                                -
                            </TaxResidenceButton>
                        )}
                    </Col>
                </Row>
            ))}
            <Row style={{ textAlign: 'center' }}>
                <Col>
                    <TaxResidenceButton onClick={addTaxResidenceCountry}>+</TaxResidenceButton>
                </Col>
            </Row>
        </>
    )
}
