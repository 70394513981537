import React, { useEffect } from 'react'

import { Text, View } from '@react-pdf/renderer'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { useFormField } from '@nickel/forms/hooks'
import CodeInput from '@nickel/ui/components/legacy/form/CodeInput'
import Field from '@nickel/ui/components/legacy/form/Field'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

import styles from '../forms/Template/Printable/styles'
import { PrintableProps } from '../types/forms'

const name = 'mastercard-number'

interface Props {
    setIsValid: (isValid: boolean) => void
}

const validator = (val: string) => String(val).length === 10

export const MasterCardEditable = ({ setIsValid }: Props) => {
    const { t } = useTranslation()
    const { error, inputProps } = useFormField<string>(name)

    useEffect(() => {
        setIsValid(validator(inputProps.value))
    }, [inputProps.value, setIsValid])

    return (
        <Fieldset legend={t('fields:nickel-information')}>
            <Row>
                <Col>
                    <Field
                        error={error}
                        htmlFor={name}
                        label={`${t('fields:mastercard-account-number')} ${t('fields:mastercard-account-number-help')}`}
                    >
                        <CodeInput {...inputProps} name={name} mask="000-000-000-0" />
                    </Field>
                </Col>
            </Row>
        </Fieldset>
    )
}

export const MasterCardPrintable = ({ values }: PrintableProps) => {
    const { t } = useTranslation()
    return (
        <View style={styles.Row}>
            <Text style={styles.Label}>{`${t('fields:mastercard-account-number')} : `}</Text>
            <Text style={styles.Value}>{values['mastercard-number']}</Text>
        </View>
    )
}
