import React, { memo, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormField } from '@nickel/forms/hooks'
import { FieldValidationRule } from '@nickel/forms/types'
import Checkbox from '@nickel/ui/components/legacy/form/Checkbox'

type Props = {
    name: string
    rules?: FieldValidationRule<boolean>[]
}

const CheckboxField = ({ name, rules = [] }: Props) => {
    const { t } = useTranslation()
    const { inputProps, setValue } = useFormField<boolean>(name, rules)

    useEffect(() => {
        setValue(false)
    }, [setValue])

    return (
        <Checkbox
            {...inputProps}
            name={name}
            checked={inputProps.value}
            value={String(inputProps.value)}
            label={`${t(`fields:${name}.label`)}`}
        />
    )
}

export default memo(CheckboxField)
