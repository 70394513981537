import React from 'react'

import { FieldValidationRule } from '@nickel/forms/types'

import Checkbox from './Checkbox'

const rules: FieldValidationRule<boolean>[] = [
    {
        validator: (val: boolean) => val,
        message: (val?: boolean) => String(val)
    }
]

const AttachId = ({ name }: { name: string }) => <Checkbox name={name} rules={rules} />

export default AttachId
