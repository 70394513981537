import common from './common.json'
import fields from './fields.json'
import forms from './forms.json'
import routes from './routes.json'

export default {
    common,
    fields,
    forms,
    routes
}
