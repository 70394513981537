import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'

import { SessionTokenProvider } from '@nickel/location/sessionTokenContext'

import App from './App'
import queryClient from './config/queryClient'
import { I18nProvider } from './i18n/I18nProvider'

ReactDOM.render(
    <I18nProvider>
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={null}>
                <SessionTokenProvider>
                    <App />
                </SessionTokenProvider>
            </Suspense>
        </QueryClientProvider>
    </I18nProvider>,
    document.getElementById('root')
)
