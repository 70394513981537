import styled from 'styled-components'

import { Color, Spacing } from '@nickel/ui/tokens'

const CheckboxWrapper = styled.div`
    border: 1px solid ${Color.OUTLINE};
    border-radius: 6px;
    margin-top: ${Spacing.MEDIUM};
    padding: ${Spacing.MEDIUM};
`

export default { CheckboxWrapper }
