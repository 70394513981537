import ScreenShots from './ScreenShots'

export function getTranslatedScreenShot(language: string) {
    switch (language) {
        case 'fr-FR':
            return ScreenShots.FormFR
        case 'de-DE':
            return ScreenShots.FormDE
        case 'en-US':
            return ScreenShots.FormENBE
        case 'es-ES':
            return ScreenShots.FormES
        case 'pt-PT':
            return ScreenShots.FormPT
        case 'fr-BE':
            return ScreenShots.FormFRBE
        case 'nl-BE':
            return ScreenShots.FormNLBE
        case 'en-BE':
            return ScreenShots.FormENBE
        default:
            return ScreenShots.FormFR
    }
}
