import React, { useEffect, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Address from '@nickel/kyc/fields/address'
import { SessionTokenContext } from '@nickel/location/sessionTokenContext'

export function ResidenceAddress() {
    const { generate } = useContext(SessionTokenContext)

    useEffect(() => {
        generate()
    }, [generate])

    const { t } = useTranslation()

    return <Address provider="googleplaces" label={t('forms:fatca.address-details')} placeholder=" " />
}
