import styled from 'styled-components'

const CheckboxGroupContainer = styled.div`
    margin: 1rem 0;
`

const CheckboxInfo = styled.p`
    margin: 0;
`
export default {
    CheckboxGroupContainer,
    CheckboxInfo
}
