import React, { ComponentType, memo } from 'react'

import { PDFDownloadLink } from '@react-pdf/renderer'
import { useFormContext } from 'react-form'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import Button from '@nickel/ui/components/legacy/Button'

import Styled from '../../../../styles'
import { FormType, PrintableProps } from '../../../../types/forms'
import { Printable } from '../../Printable'

type Props = {
    formType: FormType
    PrintableContent: ComponentType<PrintableProps>
    signatureURL: string | undefined
    cnjSignatureURL: string | undefined
}

const PrintButton = ({ formType, PrintableContent, signatureURL, cnjSignatureURL }: Props) => {
    const { t } = useTranslation()
    const { values, meta } = useFormContext()

    const isFormValid =
        (meta.isValid as boolean) &&
        Boolean(signatureURL) &&
        (values['registration-type'] === 'NOB' || Boolean(cnjSignatureURL))

    const printable = (
        <Printable
            PrintableContent={PrintableContent}
            formType={formType}
            childSignature={cnjSignatureURL}
            signature={signatureURL}
            values={values}
        />
    )
    const fileName = `${values.firstName}-${values.lastName}-${t('common:form-name', {
        context: formType
    })}.pdf`

    return (
        <Row>
            <Col>
                <Styled.ButtonContainer>
                    {isFormValid ? (
                        <PDFDownloadLink document={printable} fileName={fileName}>
                            <Button text={t('common:download')} />
                        </PDFDownloadLink>
                    ) : (
                        <Button text={t('common:download')} disabled />
                    )}
                </Styled.ButtonContainer>
            </Col>
        </Row>
    )
}

export default memo(PrintButton)
