import { format } from '@fragaria/address-formatter'
import { useTranslation } from 'react-i18next'

type InputProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
}

export function useAddressFormatter({ values }: InputProps) {
    const { t } = useTranslation()

    if (!values['is-fiscal-address-different-than-residence']) {
        return values.address?.formattedAddress
    }

    let formattedAddress = format(
        {
            houseNumber: values.fiscalAddress.fiscalAddress.streetNumber,
            road: values.fiscalAddress.fiscalAddress.streetName,
            city: values.fiscalAddress.fiscalAddress.locality,
            postcode: values.fiscalAddress.fiscalAddress.postalCode,
            countryCode: values.fiscalAddress.country
        },
        {
            output: 'array'
        }
    ).join(' ')

    const country = t(`countries:${values.fiscalAddress.country}`)

    formattedAddress = `${formattedAddress} ${country}`

    return formattedAddress
}
