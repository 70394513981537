import React from 'react'

import TransactionDispute from '../forms/Dispute/Transaction'
import WithdrawalDispute from '../forms/Dispute/Withdrawal'
import Fatca from '../forms/Fatca'
import MailEdit from '../forms/MailEdit'
import PhoneEdit from '../forms/PhoneEdit'
import i18n, { customerFormsI18nDictionaries } from '../i18n'

import { Home } from './Home'

export const formNames = ['fatca', 'mail', 'phone', 'transaction-dispute', 'withdrawal-dispute'] as const

type FORM_NAMES = typeof formNames[number]

const COMPONENTS: { [k in FORM_NAMES]: React.ComponentType } = {
    fatca: Fatca,
    mail: MailEdit,
    phone: PhoneEdit,
    'transaction-dispute': TransactionDispute,
    'withdrawal-dispute': WithdrawalDispute
}

const translationFunctions = Object.keys(customerFormsI18nDictionaries).map((locale) =>
    i18n.getFixedT(locale, 'routes')
)

export function componentForPath(pathname: string) {
    if (pathname === '/') {
        return <Home />
    }
    const visitedForm = formNames.find((formName) =>
        [
            `${window.REACT_APP_PATHNAME_PREFIX}/${formName}`,
            // pathname with suffixes expressed in any supported language must be routed too
            ...translationFunctions.map((t) => `${window.REACT_APP_PATHNAME_PREFIX}/${t(formName)}`)
        ].includes(pathname)
    )

    if (!visitedForm) return null

    const Component = COMPONENTS[visitedForm]
    return <Component />
}

export function Router() {
    return componentForPath(window.location.pathname)
}
