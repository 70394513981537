import React, { ComponentType } from 'react'

import { Image, Page, Text, View, Document } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import NickelLogo from '../../../assets/images/logo-nickel.png'
import { FormType, PrintableProps } from '../../../types/forms'

import styles from './styles'

type Props = {
    childSignature?: string
    formType: FormType
    signature?: string
    PrintableContent: ComponentType<PrintableProps>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
}

export const Printable = ({ childSignature, formType, signature, PrintableContent, values }: Props) => {
    const { t } = useTranslation()
    return (
        <Document>
            <Page size="A4" style={styles.Page} wrap>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image src={NickelLogo} style={{ width: 120, marginBottom: 9 }} />
                    <Text style={styles.Title}>{t('forms:edit.title', { context: formType })}</Text>
                </View>
                <View style={styles.Separator} />
                <PrintableContent values={values} />
                <View style={styles.Separator} />
                <View style={styles.Row}>
                    <View style={styles.Col}>
                        <Text style={styles.Label}>{`${t('common:date')} : `}</Text>
                        <Text style={styles.Value}>{format(new Date(), 'dd/MM/yyyy')}</Text>
                    </View>
                    {values['registration-type'] !== 'CNJ' && (
                        <View style={styles.Col}>
                            <Text style={styles.Label}>{`${t('fields:signature')} : `}</Text>
                            <Text style={styles.Value}>
                                {`${values.firstName} ${values['name-used'] || values.lastName}`}
                            </Text>
                            {signature ? <Image src={signature} /> : <View />}
                        </View>
                    )}
                </View>
                {values['registration-type'] === 'CNJ' && (
                    <View style={styles.Row}>
                        <View style={styles.Col}>
                            <Text style={styles.Label}>{`${t('fields:signature-minor')} : `}</Text>
                            <Text style={styles.Value}>
                                {`${values.firstName} ${values['name-used'] || values.lastName}`}
                            </Text>
                            {childSignature ? <Image src={childSignature} /> : <View />}
                        </View>
                        <View style={styles.Col}>
                            <Text style={styles.Label}>{`${t('fields:signature-guardian')} : `}</Text>
                            <Text style={styles.Value}>
                                {`${values.guardian?.firstName} ${values.guardian?.lastName}`}
                            </Text>
                            {signature ? <Image src={signature} /> : <View />}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    )
}
