import React from 'react'

import AttachId from '../AttachId'

import Styled from './style'

interface Props {
    visible: boolean
    name: string
}

const SelfieCheckbox = ({ visible, name }: Props) => {
    return visible ? (
        <Styled.CheckboxWrapper>
            <AttachId name={name} />
        </Styled.CheckboxWrapper>
    ) : null
}

export default SelfieCheckbox
