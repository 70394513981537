import React, { memo } from 'react'

import { FormType } from '../../types/forms'
import Template from '../Template'

import Editable from './Editable'
import Printable from './Printable'

const Fatca = () => <Template formType={FormType.FATCA} EditableContent={Editable} PrintableContent={Printable} />

export default memo(Fatca)
