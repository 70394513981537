import React from 'react'

import { useTranslation } from 'react-i18next'

import { useFormField } from '@nickel/forms/hooks'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'
import Input from '@nickel/ui/components/legacy/form/Input'

function FraudCommentTextArea() {
    const { t } = useTranslation()

    const { inputProps } = useFormField<string>('dispute-comment')

    return (
        <Fieldset legend={t('forms:textarea-comment-title')}>
            <Input {...inputProps} multiline placeholder={t('forms:textarea-comment-placeholder')} rows={10} />
        </Fieldset>
    )
}

export default FraudCommentTextArea
