import React, { ComponentType, memo, useState } from 'react'

import { Container } from 'react-grid-system'

import Styled from '../../../styles'
import { FormType, PrintableProps } from '../../../types/forms'

import Instructions from './footer/Instructions'
import PrintButton from './footer/PrintButton'
import SignatureFieldSet from './footer/SignatureFieldSet'
import Header from './header/Header'
import HeaderFatca from './header/HeaderFatca'

type Props = {
    EditableContent: ComponentType
    formType: FormType
    PrintableContent: ComponentType<PrintableProps>
}

const Editable = ({ EditableContent, formType, PrintableContent }: Props) => {
    const [signatureURL, setSignatureURL] = useState<string>()
    const [cnjSignatureURL, setCnjSignatureURL] = useState<string>()

    return (
        <Styled.Container>
            <Container>
                {FormType.FATCA === formType ? <HeaderFatca /> : <Header formType={formType} />}
                <EditableContent />
                <SignatureFieldSet setSignatureURL={setSignatureURL} setCnjSignatureURL={setCnjSignatureURL} />
                <PrintButton
                    formType={formType}
                    PrintableContent={PrintableContent}
                    signatureURL={signatureURL}
                    cnjSignatureURL={cnjSignatureURL}
                />
                {formType === FormType.PHONE && <Instructions formType={formType} />}
            </Container>
        </Styled.Container>
    )
}

export default memo(Editable)
