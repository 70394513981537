import React, { memo, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { getTaxResidenceCountryOptions } from '@nickel/l10n/utils'
import Select from '@nickel/ui/components/legacy/form/Select'

import { getTaxResidenceCountryList } from './countryList'

interface Props {
    value: string
    name: string
    onChange?: (value: string) => void
}
export const TaxResidenceCountry = memo(({ value, name, onChange }: Props) => {
    const {
        t,
        i18n: { language }
    } = useTranslation()

    const options = useMemo(() => getTaxResidenceCountryOptions(getTaxResidenceCountryList(language)), [language])

    return (
        <Select
            options={options}
            name={name}
            placeholder={t('forms:fatca.country')}
            light
            searchable
            value={value}
            onChange={onChange}
        />
    )
})

TaxResidenceCountry.displayName = 'TaxResidenceCountry'
