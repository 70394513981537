import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import useFormField from '@nickel/forms/hooks/useFormField'
import { nameValidator, requiredString } from '@nickel/kyc/fields/validators'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'

const name = 'firstName'

const FirstName = () => {
    const { t } = useTranslation()
    const { error, inputProps } = useFormField<string>(name, [
        requiredString,
        nameValidator(t('basic-info:error-labels.first-name'))
    ])

    return (
        <Field error={error} htmlFor={name}>
            <Input {...inputProps} name={name} label={t('basic-info:first-name')} />
        </Field>
    )
}

export default memo(FirstName)
