import React, { useState } from 'react'

import { Text, View } from '@react-pdf/renderer'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { isSet } from '@nickel/utils/lib/common'

import { CivilityEditable, CivilityPrintable } from '../../components/Civility'
import { MasterCardEditable, MasterCardPrintable } from '../../components/MasterCardNumber'
import SelfieCheckbox from '../../components/SelfieCheckbox'
import { FormType, PrintableProps } from '../../types/forms'
import Template from '../Template'
import styles from '../Template/Printable/styles'

import Mail from './Mail'

const Editable = () => {
    const [isMasterCardNumberValid, setIsMasterCardNumberValid] = useState<boolean>(false)

    return (
        <>
            <CivilityEditable />
            <MasterCardEditable setIsValid={setIsMasterCardNumberValid} />
            <Row>
                <Col xs={12} sm={6}>
                    <Mail fieldScope="old" required={false} />
                </Col>
                <Col xs={12} sm={6}>
                    <Mail fieldScope="new" />
                </Col>
            </Row>
            <SelfieCheckbox visible={!isMasterCardNumberValid} name="attach-id" />
        </>
    )
}

const Printable = ({ values }: PrintableProps) => {
    const { t } = useTranslation()

    return (
        <>
            <View style={styles.Fieldset}>
                <CivilityPrintable values={values} />
                <MasterCardPrintable values={values} />
                {!isSet(values['mastercard-number']) && <Text style={styles.Value}>{t('fields:attach-id.label')}</Text>}
            </View>
            <View style={{ ...styles.Fieldset, paddingTop: 18 }}>
                <Text style={styles.Legend}>{t('fields:mail')}</Text>
                <View style={styles.Row}>
                    <Text style={styles.Label}>{`${t('fields:mail', { context: 'old' })} : `}</Text>
                    <Text style={styles.Value}>{values.mail.old}</Text>
                </View>
                <View style={styles.Row}>
                    <Text style={styles.Label}>{`${t('fields:mail', { context: 'new' })} : `}</Text>
                    <Text style={styles.Value}>{values.mail.new}</Text>
                </View>
            </View>
        </>
    )
}

const MailEdit = () => <Template formType={FormType.MAIL} EditableContent={Editable} PrintableContent={Printable} />

export default MailEdit
