import React, { useCallback, useState } from 'react'

import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import Button from '@nickel/ui/components/legacy/Button'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'
import { Color, FontWeight } from '@nickel/ui/tokens'

import DisputeItem from './DisputeItem'
import DisputeTotal from './DisputeTotal'

const AddOperationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const Total = styled.div`
    background-color: ${Color.BACKGROUND_1};
    display: flex;
    font-weight: ${FontWeight.MEDIUM};
    justify-content: flex-end;
    margin: 1rem 0;
    padding: 1rem;
    > span {
        margin-right: 0.2rem;
    }
`

export const Disputes = () => {
    const { t } = useTranslation()
    const [disputeIds, setDisputeIds] = useState<string[]>(() => [uuidv4()])

    const addDispute = useCallback(() => {
        setDisputeIds((_disputeIds) => _disputeIds.concat(uuidv4()))
    }, [])

    const removeDispute = useCallback((id: string) => {
        setDisputeIds((_disputeIds) => _disputeIds.filter((_disputeId) => _disputeId !== id))
    }, [])

    return (
        <Fieldset legend={t('fields:disputed-transactions')}>
            {disputeIds.map((id, index) => (
                <DisputeItem key={id} id={id} index={index} removeDispute={removeDispute} />
            ))}
            <Row>
                <Col xs={12}>
                    <AddOperationContainer>
                        <Button text={t('fields:dispute-item.add')} onClick={addDispute} />
                    </AddOperationContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Total>
                        <span>{t('fields:dispute-item.total')}</span>
                        <DisputeTotal />
                    </Total>
                </Col>
            </Row>
        </Fieldset>
    )
}
