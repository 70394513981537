import React from 'react'

import { useTranslation } from 'react-i18next'

import { useFormField } from '@nickel/forms/hooks'
import FiscalAddress from '@nickel/kyc/forms/fiscalAddress'
import Checkbox from '@nickel/ui/components/legacy/form/Checkbox'

const name = 'is-fiscal-address-different-than-residence'

export function FatcaFiscalAddress() {
    const { t } = useTranslation()

    const { inputProps } = useFormField<boolean>(name)

    const isFiscalAddressDifferentThanResidence = inputProps.value

    return (
        <>
            <Checkbox
                {...inputProps}
                name={name}
                checked={isFiscalAddressDifferentThanResidence}
                value={String(isFiscalAddressDifferentThanResidence)}
                label={t('forms:fatca.fiscal-address-question')}
            />

            {isFiscalAddressDifferentThanResidence && <FiscalAddress hideAdditionalAddressInfo />}
        </>
    )
}
