import { getCurrentLng } from '@nickel/i18n'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

export function getCountryFromLanguage(language: string) {
    if (language.length === 2) return language.toUpperCase()
    if (language.length === 5) return language.slice(3, 5).toUpperCase()
    return SupportedCountryISO2.FR
}

export function setGlobalISO2(): void {
    const currentLanguage = getCurrentLng()
    const currentCountry = getCountryFromLanguage(currentLanguage)
    const code = SupportedCountryISO2[currentCountry as SupportedCountryISO2] ?? getGlobalISO2()
    localStorage.setItem('COUNTRY_CODE', code)
}
