import React, { memo } from 'react'

import { format } from 'date-fns'
import { useFormContext } from 'react-form'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import InfoAlert from '@nickel/ui/components/legacy/Alert/Info'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'
import Text from '@nickel/ui/components/legacy/Text'

import Styled from '../../../../styles'

import Signature from './Signature'

type Props = {
    setSignatureURL: (url?: string) => void
    setCnjSignatureURL: (url?: string) => void
}

const SignatureFieldSet = ({ setSignatureURL, setCnjSignatureURL }: Props) => {
    const { t } = useTranslation()
    const { values } = useFormContext()

    return (
        <Styled.BottomWrapper>
            <Row>
                <Col sm={12}>
                    <Fieldset legend={t('fields:signature')}>
                        <p>{t('fields:signature-info')}</p>
                        <Styled.Date>
                            <Text>{`${t('common:the')} `}</Text>
                            <Text>{`${format(new Date(), 'dd/MM/yyyy')},`}</Text>
                        </Styled.Date>
                        <Styled.AlertContainer>
                            <InfoAlert>{t('fields:signature-overflow')}</InfoAlert>
                        </Styled.AlertContainer>
                        {values['registration-type'] !== 'CNJ' ? (
                            <Signature onChange={setSignatureURL} />
                        ) : (
                            <Styled.SignaturesContainer>
                                <div>
                                    <span>{t('fields:signature-minor')}</span>
                                    <Signature onChange={setCnjSignatureURL} />
                                </div>
                                <div>
                                    <span>{t('fields:signature-guardian')}</span>
                                    <Signature onChange={setSignatureURL} />
                                </div>
                            </Styled.SignaturesContainer>
                        )}
                    </Fieldset>
                </Col>
            </Row>
        </Styled.BottomWrapper>
    )
}

export default memo(SignatureFieldSet)
