export enum FormType {
    FATCA = 'FATCA',
    MAIL = 'MAIL',
    PHONE = 'PHONE',
    TRANSACTION_DISPUTE = 'TRANSACTION_DISPUTE',
    WITHDRAWAL_DISPUTE = 'WITHDRAWAL_DISPUTE'
}

export type PrintableProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
}
