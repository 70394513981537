import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useFieldValue, useFormField } from '@nickel/forms/hooks'
import MailField from '@nickel/kyc/fields/mail'

const Mail = ({ fieldScope, required = true }: { fieldScope: string; required?: boolean }) => {
    const { t } = useTranslation()
    const oldValue = useFieldValue<string>('mail.old')
    const { contextValue: newValue, setMeta: setNewMeta, isValid } = useFormField<string>('mail.new')

    useEffect(() => {
        if (isValid && oldValue?.toLowerCase() === newValue?.toLowerCase()) {
            setNewMeta({ error: t('fields:mail-identical') })
        }
    }, [isValid, newValue, oldValue, setNewMeta, t])

    return (
        <MailField
            name={`mail.${fieldScope}`}
            required={required}
            label={t('fields:mail', { context: fieldScope })}
            value={fieldScope === 'old' ? oldValue : newValue}
        />
    )
}

export default Mail
