import React, { memo } from 'react'

import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { useFormSection } from '@nickel/forms/hooks'
import useFieldValue from '@nickel/forms/hooks/useFieldValue'
import Birth from '@nickel/kyc/forms/birth'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

import RegistrationType from '../../components/RegistrationType'

import Certification from './components/Certification'
import FirstName from './components/FirstName'
import LastName from './components/LastName'
import NameUsed from './components/NameUsed'
import Privacy from './components/Privacy'
import { ResidenceAddress } from './components/ResidenceAddress'
import { TaxResidence } from './components/TaxResidence'
import { FatcaFiscalAddress } from './components/TaxResidence/FatcaFiscalAddress'
import UsCitizen from './components/UsCitizen'

const Editable = () => {
    const registrationType = useFieldValue<string>('registration-type')
    const { Section } = useFormSection('guardian')

    const { t } = useTranslation()

    return (
        <>
            <Fieldset legend={t('forms:fatca.identification')}>
                <Row>
                    <Col>
                        <RegistrationType />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <FirstName />
                    </Col>
                </Row>
                <Row style={{ alignItems: 'end' }}>
                    <Col xs={12} sm={6}>
                        <LastName />
                    </Col>
                    <Col xs={12} sm={6}>
                        <NameUsed />
                    </Col>
                </Row>
            </Fieldset>
            {registrationType === 'CNJ' && (
                <Fieldset legend={t('forms:fatca.identification-guardian')}>
                    <Section>
                        <Row>
                            <Col xs={12} sm={6}>
                                <FirstName />
                            </Col>
                            <Col xs={12} sm={6}>
                                <LastName />
                            </Col>
                        </Row>
                    </Section>
                </Fieldset>
            )}
            <Fieldset legend="">
                <Birth
                    birthDatePlaceholder={t('fields:date-placeholder')}
                    isChild={registrationType === 'CNJ'}
                    key={registrationType}
                />
            </Fieldset>
            <br />
            <Fieldset legend={t('forms:fatca.complete-address')}>
                <ResidenceAddress />
            </Fieldset>
            <br />
            <Fieldset legend={t('forms:fatca.fiscal-address')}>
                <FatcaFiscalAddress />
            </Fieldset>
            <br />
            <Fieldset legend={t('forms:fatca.fiscal-address-declaration')}>
                <TaxResidence />
            </Fieldset>
            <br />
            <Fieldset legend={t('forms:fatca.us-citizenship-declaration')}>
                <UsCitizen />
            </Fieldset>
            <Privacy />
            <Certification />
        </>
    )
}

export default memo(Editable)
