import React from 'react'

import styled from 'styled-components'

import Nickel from '@nickel/ui/components/icons/Nickel'
import NickelLogo from '@nickel/ui/components/icons/NickelLogo'
import { Color } from '@nickel/ui/tokens'

const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    ${NickelLogo} {
        font-size: 2rem;
    }
    ${Nickel} {
        font-size: 4rem;
    }
`

const Logo = () => (
    <Container>
        <NickelLogo css="" fill={Color.PRIMARY} />
        <Nickel css="" />
    </Container>
)

export default Logo
