import styled, { createGlobalStyle } from 'styled-components'

import { Color, FontWeight, Spacing } from '@nickel/ui/tokens'

const Global = createGlobalStyle`
    html {
        font-size: 18px !important;
    }

    body {
        margin: 0;
        height: 100vh;
    }

    #root {
        background-color: ${Color.SECONDARY_LIGHT};
        height: 100%;
        overflow: auto;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    form {
        height: 100%;
    }

    .printable {
        height: 100vh;
        display: none;
    }

    strong {
        font-weight: ${FontWeight.MEDIUM};
    }

    #printable {
        background-color: ${Color.WHITE};
        display: none;
        padding: 4rem;

        main {
            margin: 3rem 0;

            > div {
                margin: ${Spacing.MEDIUM} 0;

                > div {
                    margin: ${Spacing.MEDIUM} 0;
                }
            }
        }

        footer {
            display: flex;
            border-top: 1px solid ${Color.OUTLINE};
            padding-top: ${Spacing.MEDIUM};

            > div {
                flex: 1;
            }
        }
    }
`

const Container = styled.div`
    background-color: ${Color.WHITE};
    max-width: 50rem;
    margin: 0 auto;
    min-height: 100%;
    padding: 0 0 3em 0;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: ${Spacing.MEDIUM};
`
export const SIGNATURE_CLASSNAME = 'Signature'

const SignatureContainer = styled.div<{ signed: boolean }>`
    margin: auto;
    position: relative;
    width: 350px;

    .${SIGNATURE_CLASSNAME} {
        border: 1px solid ${Color.OUTLINE};
        display: block;
        margin: 0 auto;
    }

    svg {
        cursor: pointer;
        fill: ${Color.ERROR};
        opacity: 0;
        position: absolute;
        right: ${Spacing.MEDIUM};
        top: ${Spacing.MEDIUM};
        transition: opacity 200ms ease-in-out;
        ${(props) => props.signed && 'opacity: 1;'}
    }
`

const Date = styled.div`
    font-weight: ${FontWeight.MEDIUM};
    text-align: center;
    margin-bottom: ${Spacing.MEDIUM};
`

const Header = styled.div`
    display: flex;
`

const AlertContainer = styled.div`
    text-align: center;
    margin: 0 auto ${Spacing.MEDIUM};
    max-width: 30rem;
`

const SignaturesContainer = styled.div`
    @media (min-width: 800px) {
        display: flex;
    }
`

const BottomWrapper = styled.div`
    margin-top: ${Spacing.MEDIUM};
`

export default {
    AlertContainer,
    BottomWrapper,
    ButtonContainer,
    Container,
    Date,
    Global,
    Header,
    SignatureContainer,
    SignaturesContainer
}
