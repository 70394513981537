import React, { memo, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useFormField from '@nickel/forms/hooks/useFormField'
import { requiredString } from '@nickel/kyc/fields/validators'
import Field from '@nickel/ui/components/legacy/form/Field'
import Radios from '@nickel/ui/components/legacy/form/Radios'

const name = 'us-citizen'

const UsCitizen = () => {
    const { error, inputProps } = useFormField<string>(name, [requiredString])
    const { t } = useTranslation()

    const options = useMemo(
        () => [
            {
                label: t('forms:fatca.us-citizen.not-citizen-nor-resident'),
                value: 'not-citizen-nor-resident'
            },
            {
                label: t('forms:fatca.us-citizen.citizen-or-resident'),
                value: 'citizen-or-resident'
            },
            {
                label: t('forms:fatca.us-citizen.citizen-but-not-resident'),
                value: 'citizen-but-not-resident'
            },
            {
                label: t('forms:fatca.us-citizen.born-in-us-but-not-citizen-nor-resident'),
                value: 'born-in-us-but-not-citizen-nor-resident'
            }
        ],
        [t]
    )

    return (
        <Field error={error} htmlFor={name} subLabel={t('forms:fatca.tick-one-box')}>
            <Radios {...inputProps} name={name} options={options} wide />
        </Field>
    )
}

export default memo(UsCitizen)
