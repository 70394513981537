import React, { useMemo } from 'react'

import { useFormContext } from 'react-form'
import { useTranslation } from 'react-i18next'

import useFormField from '@nickel/forms/hooks/useFormField'
import { requiredString } from '@nickel/kyc/fields/validators'
import Field from '@nickel/ui/components/legacy/form/Field'
import Radios from '@nickel/ui/components/legacy/form/Radios'

import { multipleTaxCountriesRadioFieldname } from '../const'

const name = multipleTaxCountriesRadioFieldname

export function MultipleTaxCountriesRadio() {
    const { t } = useTranslation()

    const { error, inputProps } = useFormField<string | undefined>(name, [requiredString])

    const { values, setValues } = useFormContext()

    function deleteTaxResidenceCountries() {
        values['additional-tax-countries'] = [{ country: '', taxNumber: '' }]
        setValues(values)
    }

    const options = useMemo(
        () => [
            {
                label: t('forms:fatca.fiscal-address-declaration-radios.France-fiscal-resident-radio'),
                value: 'only-main-country-of-fiscal-resident'
            },
            {
                label: t('forms:fatca.fiscal-address-declaration-radios.other-countries-fiscal-resident-radio'),
                value: 'other-countries-fiscal-resident'
            }
        ],
        [t]
    )

    return (
        <Field error={error} htmlFor={name} subLabel={t('forms:fatca.fiscal-address-declaration-radios.title')}>
            <Radios
                {...inputProps}
                name={name}
                options={options}
                wide
                onChange={(value) => {
                    inputProps.onChange(value)
                    if (value === 'only-main-country-of-fiscal-resident') {
                        deleteTaxResidenceCountries()
                    }
                }}
            />
        </Field>
    )
}
