import styled from 'styled-components'

const InstructionSpan = styled.span`
    margin: 3em 0 0 0;
    display: block;
`

const SubTitleSpan = styled.span`
    display: inline-block;
    margin: 3em 0 2em 0;
`

export default {
    InstructionSpan,
    SubTitleSpan
}
