import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useFieldValue, useFormField } from '@nickel/forms/hooks'
import { PHONE_NUMBER } from '@nickel/kyc/fields/names'
import PhoneNumberField from '@nickel/kyc/fields/phoneNumber'
import { SupportedCountryISO2 } from '@nickel/l10n/types'
import Field from '@nickel/ui/components/legacy/form/Field'

import i18n from '../../i18n'
import { getCountryFromLanguage } from '../../i18n/utils'

interface Props {
    fieldScope: string
    required?: boolean
    initialRegion?: string
}

const PhoneNumber = (props: Props) => {
    const { t } = useTranslation()
    const defaultBrowserCountry = getCountryFromLanguage(i18n.language)
    const {
        fieldScope,
        required,
        initialRegion = defaultBrowserCountry === 'US' ? SupportedCountryISO2.BE : defaultBrowserCountry
    } = props
    const oldValue = useFieldValue<string>('phoneNumber.old')
    const { contextValue: newValue, setMeta: setNewMeta, isValid } = useFormField<string>('phoneNumber.new')

    useEffect(() => {
        if (isValid && oldValue === newValue) {
            setNewMeta({ error: t('fields:phone-identical') })
        }
    }, [t, isValid, newValue, oldValue, setNewMeta])

    const name = fieldScope
    // because the id ot the input will be the same as the name (!)
    const labelHtmlFor = `${PHONE_NUMBER}.${name}`

    return (
        <Field label={t('fields:phone-number', { context: fieldScope })} htmlFor={labelHtmlFor}>
            <PhoneNumberField name={name} initialRegion={initialRegion} required={required} />
        </Field>
    )
}

export default PhoneNumber
