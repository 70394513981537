import { SupportedLocale } from '@nickel/i18n/lib/types'

const SUPPORTED_LANGUAGES = [
    SupportedLocale.DE_DE,
    SupportedLocale.EN_US,
    SupportedLocale.ES_ES,
    SupportedLocale.FR_FR,
    SupportedLocale.FR_BE,
    SupportedLocale.NL_BE,
    SupportedLocale.PT_PT
]

export function convertDetectedLanguage(language: string) {
    const lowerCaseLng = language.toLocaleLowerCase()
    if (SUPPORTED_LANGUAGES.map((supportedLanguage) => supportedLanguage.toLocaleLowerCase()).includes(lowerCaseLng)) {
        return language
    }
    const shortLang = language.split('-')[0]

    // return the first found country on the SUPPORTED_LANGUAGES (fr return fr-FR not fr-BE)
    return (
        SUPPORTED_LANGUAGES.find((supportedLanguage) => supportedLanguage.split('-')[0] === shortLang) ||
        SupportedLocale.FR_FR
    )
}
