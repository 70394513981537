import React, { useMemo } from 'react'

import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

import { CivilityPrintable } from '../../../components/Civility'
import Table from '../../../components/Pdf/Table'
import { PrintableProps } from '../../../types/forms'
import styles from '../../Template/Printable/styles'

const Printable = ({ values }: PrintableProps) => {
    const { t } = useTranslation()

    const disputeData = useMemo(() => {
        return Object.keys(values)
            .filter((key) => key.startsWith('dispute-item-') && values[key] !== undefined)
            .map((key, index) => ({
                amount: values[key].amount,
                date: values[key].date,
                description: values[key].description,
                hour: values[key].hour,
                id: index
            }))
    }, [values])

    return (
        <>
            <View style={styles.Fieldset}>
                <CivilityPrintable values={values} />
                <View style={styles.Row}>
                    <Text style={styles.Label}>{`${t('fields:birthdate')} : `}</Text>
                    <Text style={styles.Value}>{values.birthDate}</Text>
                </View>
            </View>
            <View style={styles.Fieldset}>
                <View style={styles.Row}>
                    <Text style={styles.Label}>{`${t('fields:withdrawal-location')} : `}</Text>
                    <Text style={styles.Value}>{values.address?.label}</Text>
                </View>
                <View style={styles.Row}>
                    <Text style={styles.Label}>{`${t('fields:bank-name')} : `}</Text>
                    <Text style={styles.Value}>{values['bank-name']}</Text>
                </View>
                <View style={styles.Row}>
                    <Text style={styles.Label}>{`${t('fields:agency-name')} : `}</Text>
                    <Text style={styles.Value}>{values['agency-name']}</Text>
                </View>
                <View style={styles.Row}>
                    <Text style={styles.Value}>{`${t('fields:joined-receipt.label')} `}</Text>
                    <Text style={styles.Label}>
                        {values['joined-receipt'] === true ? t('common:yes') : t('common:no')}
                    </Text>
                </View>
            </View>
            <View style={{ ...styles.Fieldset, paddingTop: 18 }}>
                <Text style={styles.Legend}>{t('fields:disputed-transactions')}</Text>
                <Table dispute={disputeData} values={values} />
            </View>

            <View style={{ paddingTop: 18 }} break>
                <Text style={styles.Label}>{`${t('common:undersigned')}`}</Text>
                <Text style={styles.Value}>
                    {`${values.firstName} ${values.lastName} ${t('common:fraud-attestation')}`}
                </Text>
            </View>
        </>
    )
}
export default Printable
