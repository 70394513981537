import { memoize } from 'lodash'

import { COUNTRY_CODE } from '@nickel/l10n/constants'
import { Country } from '@nickel/l10n/types'
import { putFirstByISO2, getCountries } from '@nickel/l10n/utils'

type LocaleOrLanguage = string // may be two letters (language) or two letter dash two letters (locale)
type CountryCodeISO2 = string

const PROBABLE_COUNTRY_CODE_BY_LANGUAGE: { [_: string]: CountryCodeISO2[] } = {
    fr: [COUNTRY_CODE.FR, COUNTRY_CODE.BE],
    // US will be filtered in @nickel/l10n
    // when building tax residence country selector options, for business reasons
    // but it has to appear here for consistency of the getTaxResidenceCountryList function
    en: [COUNTRY_CODE.GB, COUNTRY_CODE.US],
    es: [COUNTRY_CODE.ES],
    de: [COUNTRY_CODE.DE],
    pt: [COUNTRY_CODE.PT],
    nl: [COUNTRY_CODE.NL]
}

/**
 * Gives a list of country ISO code (2 letters uppercase) corresponding to a given locale or langage code.
 * These countries are the most probable residence countries for a speaker of the language.
 */
export const getTaxResidenceCountryList = memoize((localeOrLanguage: LocaleOrLanguage): Country[] => {
    const language = localeOrLanguage.substring(0, 2)
    const countryCodesForLanguage = PROBABLE_COUNTRY_CODE_BY_LANGUAGE[language] || []
    const countries = putFirstByISO2(countryCodesForLanguage, getCountries())
    return countries
})
