import React from 'react'

import { FormType } from '../../../types/forms'
import Template from '../../Template'

import Editable from './Editable'
import { Printable } from './Printable'

const TransactionDispute = () => (
    <Template formType={FormType.TRANSACTION_DISPUTE} EditableContent={Editable} PrintableContent={Printable} />
)

export default TransactionDispute
