import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormField } from '@nickel/forms/hooks'
import { FieldValidationRule } from '@nickel/forms/types'
import { requiredString } from '@nickel/kyc/fields/validators'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'

const name = 'agency-name'

const rules: FieldValidationRule[] = [requiredString]

const AgencyName = () => {
    const { t } = useTranslation()
    const { error, inputProps } = useFormField<string>(name, rules)

    return (
        <Field error={error} htmlFor={name}>
            <Input {...inputProps} label={t('fields:agency-name')} />
        </Field>
    )
}

export default memo(AgencyName)
