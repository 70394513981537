import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import i18n, { addTranslations, Dictionnary } from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { addLibTranslations as addKycTranslations } from '@nickel/kyc/lib/i18n'
import { addLibTranslations as addL10nTranslations } from '@nickel/l10n/lib/i18n'

import { convertDetectedLanguage } from './i18n.utils'
import de from './languages/de_DE'
import en from './languages/en_US'
import es from './languages/es_ES'
import frbe from './languages/fr_BE'
import fr from './languages/fr_FR'
import nlbe from './languages/nl_BE'
import pt from './languages/pt_PT'
import root from './languages/root'
import { setGlobalISO2 } from './utils'

export const customerFormsI18nDictionaries: Dictionnary = {
    [SupportedLocale.DE_DE]: de,
    [SupportedLocale.EN_US]: en,
    [SupportedLocale.ES_ES]: es,
    [SupportedLocale.FR_BE]: frbe,
    [SupportedLocale.FR_FR]: fr,
    [SupportedLocale.NL_BE]: nlbe,
    [SupportedLocale.PT_PT]: pt,
    [SupportedLocale.ROOT]: root
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            // translation language should be two letter code only
            // by virtue of https://www.i18next.com/principles/fallback#variant-resolving-fallback-from-dialects-or-scripts
            // so that if user preferred language is "es-ES", then it fallbacks to "es" which would be found
            // and of course if user preferred language is "es", then it is just found
            // but translation languages have been defined to "es-ES", so the fallback mechanism is broken
            // we have to provide this artificial callback (the other way) for "es" language to be usable
            es: [SupportedLocale.ES_ES, SupportedLocale.ROOT],
            fr: [SupportedLocale.FR_FR, SupportedLocale.FR_BE, SupportedLocale.ROOT], // and that is a good reason (amongst others) why we cannot put adresses or any other non-linguistical data in translations strings
            de: [SupportedLocale.DE_DE, SupportedLocale.ROOT],
            en: [SupportedLocale.EN_US, SupportedLocale.ROOT],
            nl: [SupportedLocale.NL_BE, SupportedLocale.ROOT],
            pt: [SupportedLocale.PT_PT, SupportedLocale.ROOT],
            default: [SupportedLocale.ROOT]
        },
        react: {
            useSuspense: true
        },
        detection: {
            caches: [],
            order: [
                // for unit tests, but should be removed when testing manually @ local (probable false positives and false negatives)
                'sessionStorage',
                // nice for manual tests
                'querystring',
                // the user case
                'navigator'
            ],
            convertDetectedLanguage
        }
    })
    .then(setGlobalISO2)

addTranslations(customerFormsI18nDictionaries)
addKycTranslations()
addL10nTranslations()

export default i18n
