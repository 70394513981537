import React, { useEffect } from 'react'

import { setConfiguration } from 'react-grid-system'
import { ThemeProvider } from 'styled-components'

import LibUiGlobalStyle from '@nickel/ui/components/config/GlobalStyle'
import bo from '@nickel/ui/themes/bo'

import Router from './router'
import Styled from './styles'

const getGtmTrackingModule = () => import('@nickel/tracking/gtm')

setConfiguration({ containerWidths: [540, 740, 740, 740, 740] })

declare global {
    interface Window {
        REACT_APP_GTM_ID: string
        REACT_APP_PATHNAME_PREFIX: string
    }
}
const initializeGTM = () =>
    getGtmTrackingModule().then((gtm) => {
        gtm.init(window.REACT_APP_GTM_ID)
    })

const App = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            initializeGTM()
        }
    }, [])
    return (
        <ThemeProvider theme={bo}>
            <LibUiGlobalStyle />
            <Styled.Global />
            <Router />
        </ThemeProvider>
    )
}

export default App
