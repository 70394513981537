import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

const Certification = () => {
    const { t } = useTranslation()
    return (
        <Fieldset legend={t('forms:fatca.certification.title')}>
            <p>{t('forms:fatca.certification.content')}</p>
        </Fieldset>
    )
}

export default memo(Certification)
