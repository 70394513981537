import { DefaultOptions } from 'react-query'

const _queryConfig: DefaultOptions = {
    queries: {
        refetchOnWindowFocus: false
    }
}

const _testQueryConfig: DefaultOptions = {
    ..._queryConfig,
    queries: {
        ..._queryConfig.queries,
        retry: false,
        retryOnMount: false,
        refetchOnReconnect: false
    }
}

const queryConfig = process.env.NODE_ENV === 'test' ? _testQueryConfig : _queryConfig

export default queryConfig
